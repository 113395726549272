/* eslint-disable no-nested-ternary */
/* eslint-disable no-negated-condition */
/* eslint-disable camelcase */
/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { useParams } from "react-router-dom";
import React from "react";
import { Temporal } from "@js-temporal/polyfill";

import NoteElement from "./note-element.jsx";

import { formatArea, formatEuro } from "~/src/modules/formatters.js";
import ContentSection from "~/src/ui/sections/content-section.jsx";
import dateFormatter from "~/src/modules/date-formatter.js";
import useProject from "~/src/hooks/use-project.js";

/**
 * @typedef {import("react").FunctionComponent} FunctionComponent
 */

/**
 * The ProjectOverviewTransactions component.
 *
 * @type {FunctionComponent}
 */
const ProjectOverviewTransactions = ({ historic }) => {
  const { id } = useParams();
  const {
    project,
    isError
  } = useProject(id);

  if (isError) {
    return null;
  }

  const {
    transactions
  } = project;

  const transactionContent =
    transactions
      .filter(({ historic: isHistoric }) => isHistoric === historic)
      .sort((transactionA, transactionB) => {
        const dateA = new Date(transactionA.transaction_date).toTemporalInstant();
        const dateB = new Date(transactionB.transaction_date).toTemporalInstant();

        return Temporal.Instant.compare(dateA, dateB) * -1;
      })
      .sort((transactionA, transactionB) => {
        if (transactionA.sorting === null && transactionB.sorting === null) {
          return 0;
        }
        else if (transactionA.sorting === null) {
          return 1;
        }
        else if (transactionB.sorting === null) {
          return -1;
        }

        return transactionA.sorting - transactionB.sorting;
      })
      .map(({
        id: transactionId,

        tz_number: tzNumber,
        tz_year: tzYear,

        kv_url: kvUrl,

        transaction_date: transactionDate,
        transaction_category: {
          code: transactionCategoryCode,
          name: transactionCategoryName
        },
        sale_price_net: salePriceNet,
        bauzins_net: bauzinsNet,
        bauzins_gross: bauzinsGross,

        kg: {
          bezirksgericht: {
            name: bezirksgerichtName,
            code: bezirksgerichtCode
          },
          name: kgName,
          code: kgCode

        },

        buyer,
        seller,
        buyer_private,
        seller_private,

        brez,
        baurechtsdauer,
        baurechtsdauer_date: baurechtsdauerDate,

        ez_numbers: ezNumbers,
        gst_numbers: gstNumbers,
        shares,

        property_area: propertyArea,
        build_area: buildArea,
        green_area: greenArea,
        usable_area: usableArea,

        notes
      }) => {
        const isBaurecht = transactionCategoryCode === "baurecht";

        return (
          <div key={`parent_${transactionId}`} className="flex flex-col xl:flex-row justify-between w-full gap-6">
            <div className="flex flex-col w-full jusitfy-between xl:min-w-[60%] xl:max-w-[60%]">
              <div className="flex flex-row justify-between w-full">
                <div className="flex flex-col gap-5">
                  <div>
                    <span className="font-semibold text-sm">
                    Objektkategorie: <span className="font-normal text-sm">
                        {transactionCategoryName}
                      </span>
                    </span>
                  </div>
                  <div>
                    <div className="flex gap-3">{
                      [
                        (bezirksgerichtName && bezirksgerichtCode) && [
                          "BG",
                          `${bezirksgerichtName}`
                        ],
                        (kgName && kgCode) && [
                          "KG",
                          `${kgName} (${kgCode})`
                        ],
                        (ezNumbers.length > 0) && [
                          "EZ",
                          `${ezNumbers.join(", ")}`
                        ]
                      ]
                        .filter((value) => (value !== false && value !== null))
                        .map(([
                          label,
                          value
                        ], index, array) => (<React.Fragment key={`${label}_${transactionId}`}>
                          <span key={`${label}_${transactionId}`} className="font-semibold">{`${label}: `} <span className="font-normal" key={`${index}_${transactionId}`} >{value}{index !== (array.length - 1)}</span> </span>
                        </React.Fragment>))
                    }
                    </div>
                    {(isBaurecht) &&
                    <div className="flex gap-3">
                      {
                        [
                          [
                            "Baurechtsdauer",
                            baurechtsdauer ? `${baurechtsdauer} Jahre` : "-"
                          ],
                          [
                            "Baurecht bis",
                            baurechtsdauerDate ? dateFormatter(baurechtsdauerDate) : "-"
                          ],
                          [
                            "BREZ",
                            brez || "-"
                          ]
                        ]
                          .map((
                            [
                              label,
                              value
                            ]
                          ) => (
                            <span key={`${label}_${transactionId}`}>
                              <span className="font-semibold">
                                {label}:
                              </span>
                              <span> </span>
                              <span className="font-normal whitespace-nowrap">
                                {value}
                              </span>
                            </span>
                          ))

                      }
                    </div>
                    }
                    <div className="flex gap-3">
                      {
                        [
                          [
                            "Baufläche (Gebäude)",
                            buildArea ? formatArea(buildArea) : "-"
                          ],
                          [
                            "Baufläche (begrünt)",
                            greenArea ? formatArea(greenArea) : "-"
                          ]
                        ]
                          .map(([
                            label,
                            value
                          ], index, array) => (<React.Fragment key={`${label}_${transactionId}`}>
                            <span key={`${label}_${transactionId}`} className="font-semibold">{`${label}: `} <span className="font-normal whitespace-nowrap" key={`${index}_${transactionId}`} >{value}{index !== (array.length - 1)}</span> </span>

                          </React.Fragment>))
                      }
                    </div>
                    {
                      (usableArea) && <div>
                        {
                          [
                            [
                              "Nutzfläche",
                              usableArea ? formatArea(usableArea) : "-"
                            ]
                          ]
                            .map(([
                              label,
                              value
                            ], index, array) => (<React.Fragment key={`${label}_${transactionId}`}>
                              <span key={`${label}_${transactionId}`} className="font-semibold">{`${label}: `} <span className="font-normal" key={`${index}_${transactionId}`} >{value}{index !== (array.length - 1)}</span></span>
                            </React.Fragment>))
                        }
                      </div>
                    }
                    <div>
                      {
                        [
                          (gstNumbers.length > 0) && [
                            "GST-Nr.",
                            `${gstNumbers.join(", ")}`
                          ]
                        ]
                          .filter((value) => (value !== false && value !== null))
                          .map(([
                            label,
                            value
                          ], index, array) => (<React.Fragment key={`${label}_${transactionId}`}>
                            <span key={`${label}_${transactionId}`} className="font-semibold">{`${label}: `}</span>
                            <span key={`${index}_${transactionId}`} >{value}{index !== (array.length - 1) && ", "}</span>
                          </React.Fragment>))
                      }
                    </div>
                    <div className="max-w-[40rem]">
                      <span className="font-semibold">{((isBaurecht)
                        ? "Bauberechtigter: "
                        : "Käufer: ")}
                      </span>
                      {
                        `${(buyer !== null)
                          ? buyer.name
                          : (buyer_private !== null)
                            ? "Privat"
                            : "-"}`
                      }
                    </div>
                    <div className="max-w-[40rem]">
                      <span className="font-semibold">{((isBaurecht)
                        ? "Baurechtsgeber: "
                        : "Verkäufer: ")}</span>
                      {
                        `${(seller !== null)
                          ? seller.name
                          : (seller_private !== null)
                            ? "Privat"
                            : "-"}`
                      }
                    </div>
                  </div>
                </div>
                <div className="flex justify-between flex-col">
                  <div className="flex flex-col items-end">
                    <div>
                      <span className="font-semibold" key={`vom_${transactionId}`}>{dateFormatter(transactionDate)}</span>
                    </div>
                    <div>
                      {(kvUrl)
                        ? <a key={`tzNrLink_${transactionId}`} href={kvUrl} target="_blank" className="font-bold text-right inline-flex items-center gap-1 cursor-pointer hover:underline">
                          <span key={`tzNr_${transactionId}`}>TZ: <span className="font-normal">{tzNumber}/{dateFormatter(tzYear, "year")}</span></span>
                          <ArrowTopRightOnSquareIcon key={`icon_${transactionId}`} className="inline w-4 h-4 text-primary" />
                        </a>
                        : <span key={`tzNrParent_${transactionId}`} className={"font-bold text-right inline-flex items-center gap-1"}>
                          <span key={`tzNr_${transactionId}`}>TZ: <span className="font-normal">{tzNumber}/{dateFormatter(tzYear, "year")}</span></span>
                        </span>
                      }
                    </div>
                  </div>
                  <div className="flex flex-col items-end text-right">
                    {
                      [
                        [
                          "GST-Fläche",
                          propertyArea ? formatArea(propertyArea) : "-"
                        ],
                        (Boolean(shares)) && [
                          "Anteile",
                          `${shares}`
                        ],
                        (typeof salePriceNet === "number") && [
                          "Kaufpreis",
                          `${formatEuro(salePriceNet)}`
                        ],
                        (typeof bauzinsNet === "number" || typeof bauzinsGross === "number") && [
                          "Bauzins / Monat",
                          ""
                        ],
                        (typeof bauzinsNet === "number") && [
                          "Netto",
                          `${formatEuro(bauzinsNet)}`
                        ],
                        (typeof bauzinsGross === "number") && [
                          "Brutto",
                          `${formatEuro(bauzinsGross)}`
                        ]
                      ]
                        .filter((value) => (value !== false && value !== null))
                        .map(([
                          label,
                          value
                        ], index, array) => (<React.Fragment key={`${label}_${transactionId}`}>
                          <span key={`${label}_${transactionId}`} className="font-semibold">{(label !== "Bauzins / Monat") ? `${label}: ` : label} <span className="font-normal whitespace-nowrap" key={`${index}_${transactionId}`} >{value}{index !== (array.length - 1)}</span></span>

                        </React.Fragment>))
                    }

                  </div>
                </div>
              </div>
            </div>
            <NoteElement id={`notes_${transactionId}`} notes={notes} />
          </div>

        );
      });

  return (
    (transactionContent.length === 0 && historic)
      ? null
      : <ContentSection id="transactions" title={`${(historic) ? "Historische Liegenschaftsankäufe" : "Liegenschaftsankäufe"}`}>
        <ul key="ul" className="flex flex-col gap-6 w-full">
          {
            (transactionContent.length)
              ? transactionContent
              : "-"
          }
        </ul>
      </ContentSection>
  );
};

export default ProjectOverviewTransactions;
