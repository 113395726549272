import { useEffect, useState } from "react";
import cn from "classnames";

import { formatArea, formatEuro } from "~/src/modules/formatters";
import dateFormatter from "~/src/modules/date-formatter";
import PurchaseSlide from "~/src/features/purchase-slide";
import useTracking from "~/src/hooks/use-tracking";

/**
 *
 * @param root0
 * @param root0.isLoading
 * @param root0.purchases
 * @param root0.loadingItems
 * @param root0.query
 * @param root0.noActiveColumn
 */
export default function PurchaseList({
  isLoading, purchases, loadingItems = 5, query, noActiveColumn
}) {
  const { trackPageView } = useTracking();
  const [
    showPurchaseDetails,
    setShowPurchaseDetails
  ] = useState(false);
  const [
    activePurchaseDetail,
    setActivePurchaseDetail
  ] = useState(false);
  const [
    activeColumn,
    setActiveColumn
  ] = useState("dateVerbuecherung");

  function handlePurchaseDetailClick(purchase) {
    setActivePurchaseDetail(purchase);
    setShowPurchaseDetails(true);
    trackPageView(purchase.doc_id, "Liegenschaftsankauf");
  }

  function hidePurchaseDetails() {
    setShowPurchaseDetails(false);
  }

  useEffect(() => {
    if (noActiveColumn) {
      setActiveColumn(null);
    }
    else if (query) {
      setActiveColumn(query);
    }
  }, [query]);

  return (
    <div>
      <PurchaseSlide active={showPurchaseDetails} hide={hidePurchaseDetails} purchase={activePurchaseDetail} />

      <div className="ml-4 sm:ml-0 mt-2 mb-4 flex flex-col">
        <div id="scrollable-table" className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow-sm overflow-hidden border border-b border-gray-200 sm:rounded">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-100">
                  <tr>
                    <th scope="col" className="px-4 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      Erfassung
                    </th>
                    <th scope="col" className="px-4 py-3 whitespace-nowrap text-left text-xs font-semibold text-gray-700 uppercase">
                      KV-Datum
                    </th>
                    <th scope="col" className="px-4 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      Adresse
                    </th>
                    <th scope="col" className="px-4 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      Käufer
                    </th>
                    <th scope="col" className="px-4 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      Objektart
                    </th>
                    <th scope="col" className="px-4 py-3 whitespace-nowrap text-left text-xs font-semibold text-gray-700 uppercase">
                      GST-Fläche
                    </th>
                    <th scope="col" className="px-4 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      Kaufpreis
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {isLoading &&
                    [...Array(loadingItems)].map((e, i) => (
                      <tr key={`purchase_skeleton_${i}`} className="hover:bg-gray-50 cursor-pointer">
                        <td className="w-20 px-4 h-16"><span className="block w-full h-5 bg-gray-300 rounded" /></td>
                        <td className="w-20 px-4 h-16"><span className="block w-full h-5 bg-gray-300 rounded" /></td>
                        <td className="w-64 px-4 h-16"><span className="block w-full h-5 bg-gray-300 rounded" /></td>
                        <td className="max-w-xs px-4 h-16"><span className="block w-full h-5 bg-gray-300 rounded" /></td>
                        <td className="w-32 px-4 h-16"><span className="block w-full h-5 bg-gray-300 rounded" /></td>
                        <td className="w-20 px-4 h-16"><span className="block w-full h-5 bg-gray-300 rounded" /></td>
                        <td className="w-20 px-4 h-16"><span className="block w-full h-5 bg-gray-300 rounded" /></td>
                      </tr>
                    ))
                  }

                  {purchases?.length === 0 &&

                    <tr className="bg-white">
                      <td colSpan="7" className="px-4 py-4 whitespace-nowrap text-center font-light text-gray-900">keine Transaktionen für die ausgewählten Kriterien gefunden</td>
                    </tr>
                  }

                  {purchases?.map((purchase) => (
                    <tr key={purchase.id} className="hover:bg-gray-50 cursor-pointer" onClick={() => handlePurchaseDetailClick(purchase)}>
                      <td className={cn("px-4 py-4 w-20 whitespace-nowrap text-sm text-center", {
                        "text-primary font-bold": activeColumn === "dateVerbuecherung",
                        "text-gray-900 font-medium": activeColumn !== "dateVerbuecherung"
                      })}>{dateFormatter(purchase.date_verbuecherung)}</td>
                      <td className={cn("px-4 py-4 w-20 whitespace-nowrap text-sm text-center", {
                        "text-primary font-bold": activeColumn === "dateKV",
                        "text-gray-900 font-medium": activeColumn !== "dateKV"
                      })}>{dateFormatter(purchase.date_kv)}</td>
                      <td className="px-4 py-4 w-64 whitespace-nowrap text-sm truncate text-gray-600">
                        <span className={cn({
                          "text-primary font-bold": activeColumn === "plz"
                        })}>{purchase.zipcode} {purchase.city}</span>{purchase.street ? "," : ""} {purchase.street} {purchase.housenumber}<br />{purchase.state}</td>
                      <td className="px-4 py-4 max-w-xs whitespace-nowrap text-sm text-gray-600 truncate">{purchase.buyer}<br />{purchase.buyer_fbnr && purchase.buyer_fbnr !== "N/A" ? purchase.buyer_fbnr : null}</td>
                      <td className="px-4 py-4 w-32 whitespace-nowrap text-sm text-gray-600">{purchase.object_type ? purchase.object_type : "keine Angabe"}</td>
                      <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-600">{purchase.gst_area ? formatArea(purchase.gst_area) : "keine Angabe"}</td>
                      <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-600">{purchase.price_netto ? formatEuro(parseFloat(purchase.price_netto)) : "keine Angabe"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
