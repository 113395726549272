/* eslint-disable max-params */
import dayjs from "dayjs";

/**
 *
 * @param val
 */
export const formatArea = function (val) {
  if (val) {
    return `${parseFloat(val).toLocaleString("de", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    })} m²`;
  }

  return "";
};

/**
 *
 * @param val
 */
export const formatAreaInt = function (val) {
  if (val) {
    return `${parseInt(val).toLocaleString("de")} m²`;
  }

  return "";
};

/**
 *
 * @param val
 */
export const formatNumber = function (val) {
  if (val) {
    return parseFloat(val).toLocaleString("de", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }

  return "";
};

/**
 *
 * @param val
 */
export const formatNumberInt = function (val) {
  if (val) {
    return parseFloat(val).toLocaleString("de", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
  }

  return "";
};

/**
 *
 * @param val
 */
export const formatEuro = function (val) {
  if (typeof val === "number") {
    return `€ ${parseFloat(val).toLocaleString("de", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}`;
  }

  return "";
};

/**
 *
 * @param val
 */
export const formatEuroHistoric = function (val) {
  if (typeof val === "number") {
    return `€ ${parseFloat(val).toLocaleString("de", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })}`;
  }

  return "";
};

/**
 *
 * @param val
 */
export const formatEuroPerMeterSquared = function (val) {
  return `${formatEuro(val)}/m²`;
};

/**
 *
 * @param options
 * @param options.val
 * @param options.currency
 */
export const formatCurrency = function ({ val, currency = "EUR" }) {
  if (val && currency === "EUR") {
    return `€ ${parseFloat(`${val}`.replace(",", ".")).toLocaleString("de", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}`;
  }
  if (val) {
    return `${parseFloat(`${val}`.replace(",", ".")).toLocaleString("de", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })} ${currency}`;
  }

  return "";
};

/**
 *
 * @param options
 * @param options.val
 * @param options.currency
 */
export const formatCurrencyInt = function ({ val, currency = "EUR" }) {
  if (val && currency === "EUR") {
    return `€ ${parseInt(`${val}`.replace(",", ".")).toLocaleString("de")}`;
  }
  if (val) {
    return `${parseInt(`${val}`.replace(",", ".")).toLocaleString("de")} ${currency}`;
  }

  return "";
};

/**
 *
 * @param val
 */
export const formatPrice = function (val) {
  if (val) {
    return val.toLocaleString("de");
  }

  return "";
};

/**
 *
 * @param date
 */
export const formatYear = function (date) {
  try {
    if (date) {
      return dayjs(date).format("YYYY");
    }
  }
  catch (error) {
    return null;
  }

  return null;
};

/**
 *
 * @param date
 */
export const formatMonth = function (date) {
  if (date) {
    return dayjs(date).format("MM/YYYY");
  }

  return null;
};

/**
 *
 * @param date
 */
export const formatMonthDotSeparated = function (date) {
  if (date) {
    return dayjs(date).format("MM.YYYY");
  }

  return null;
};

/**
 *
 * @param options
 * @param options.date
 * @param options.withTime
 */
export const formatDate = function ({ date = null, withTime = false }) {
  if (date === null) {
    return null;
  }

  if (withTime) {
    return dayjs(date).format("DD.MM.YYYY HH:mm");
  }

  return dayjs(date).format("DD.MM.YYYY");
};

/**
 *
 * @param singular
 * @param plural
 * @param none
 * @param length
 */
export const pluralize = function (singular = "", plural = "", none = "", length = 0) {
  if (!length || length === 0) {
    return none;
  }

  return length > 1 ? plural : singular;
};
