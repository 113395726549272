import { Dialog, Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import cn from "classnames";
import {
  Fragment, useEffect, useState
} from "react";

import PurchaseMap from "../maps/purchase-map";

import FeedbackModal from "~/src/features/feedback-modal";
import dateFormatter from "~/src/modules/date-formatter";
import { formatArea, formatEuro } from "~/src/modules/formatters";
import CloseIcon from "~/src/ui/icons/close-icon";

/**
 *
 * @param root0
 * @param root0.active
 * @param root0.hide
 * @param root0.purchase
 */
export default function PurchaseSlide({
  active, hide, purchase
}) {
  const [
    showFeedback,
    setShowFeedback
  ] = useState(false);
  const [
    showMultipleBuyers,
    setShowMultipleBuyers
  ] = useState(false);
  const [
    showMultipleSellers,
    setShowMultipleSellers
  ] = useState(false);
  const [
    slideIsOpen,
    setSlideIsOpen
  ] = useState(false);

  useEffect(() => {
    setShowMultipleBuyers(false);
    setShowMultipleSellers(false);
  }, [active]);

  return (
    <Transition.Root show={active} afterEnter={() => {
      setSlideIsOpen(true);
    }} afterLeave={() => {
      setSlideIsOpen(false);
    }} as={Fragment}>
      <Dialog as="div" static className="z-[150] fixed inset-0 overflow-hidden" open={active} onClose={hide}>

        <FeedbackModal show={showFeedback} close={() => setShowFeedback(false)} entity_id={purchase?.doc_id} feedback_type="transaction" feedback_type_label={purchase?.doc_id} />

        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-200 sm:duration-200"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-200 sm:duration-200"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative w-screen max-w-4xl">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-200"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
                    <button
                      className="rounded-md text-white hover:text-gray-300 focus:outline-none focus:ring-0 focus:ring-white"
                      onClick={hide}
                    >
                      <span className="sr-only">Schließen</span>
                      <CloseIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-full flex flex-col pb-6 bg-white shadow-xl overflow-y-scroll">

                  <div className="px-4 sm:px-12 h-16 flex justify-between items-center shrink-0 bg-primary">
                    <h2 id="slide-over-heading" className="text-lg font-medium text-white">
                      Details zum Kaufvertrag
                    </h2>
                  </div>

                  <div className="mt-4 flex-1">

                    <dl className="px-4 sm:px-8">

                      <div className="pl-4">
                        <div className="flex justify-between items-center">
                          <h3 className="text-2xl font-semibold text-gray-700">{purchase.object_type}</h3>
                          <button type="button" onClick={() => setShowFeedback(true)} className="px-3 py-1 text-sm bg-primary text-white hover:bg-primary-lighter border border-primary rounded focus:outline-none">Feedback</button>
                        </div>
                        <div className="mt-4 text-gray-900">
                          <p>{purchase.state}</p>
                          <p className="leading-5">{purchase.zipcode} {purchase.city}{purchase.street ? "," : ""} {purchase.street} {purchase.housenumber}</p>
                        </div>
                      </div>

                      <div className="px-4">

                        <div className="mt-4 sm:grid sm:grid-cols-3 sm:gap-x-4">
                          <dt className="font-semibold text-gray-800">KV-Datum</dt>
                          <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-2">{dateFormatter(purchase.date_kv)}</dd>
                        </div>

                        <div className="mt-0.5 sm:grid sm:grid-cols-3 sm:gap-x-4">
                          <dt className="font-semibold text-gray-800">Erfassungsdatum</dt>
                          <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-2">{dateFormatter(purchase.date_verbuecherung)}</dd>
                        </div>

                      </div>

                      <div className="mt-4 bg-gray-50">

                        <div className="px-4 pt-3 sm:grid sm:grid-cols-3 sm:gap-x-4 relative">

                          <dt className={cn("font-semibold text-primary flex items-center h-6 w-max", { "cursor-pointer group": purchase?.buyers?.length > 3 })} onClick={() => purchase?.buyers?.length > 3 && setShowMultipleBuyers(!showMultipleBuyers)}>
                            <span className={cn({ "group-hover:underline": purchase?.buyers?.length > 3 })}>Käufer</span>
                            {
                              purchase?.buyers?.length > 3 && (
                                <>
                                  {!showMultipleBuyers &&
                                    <button type="button">
                                      <ChevronDownIcon className="w-6 h-6 -ml-0.5 mt-0.5 text-primary" />
                                    </button>
                                  }
                                  {showMultipleBuyers &&
                                    <button type="button">
                                      <ChevronUpIcon className="w-6 h-6 -ml-0.5 mt-0.5 text-primary" />
                                    </button>
                                  }
                                  <span className="text-[0.7em] rounded-full bg-primary text-white px-1 ml-1" >{purchase?.buyers?.length}</span>
                                </>
                              )
                            }
                          </dt>
                          <dd className="text-gray-900 sm:col-span-2 leading-snug relative">
                            {
                              purchase && (
                                <>
                                  {purchase?.buyers.length > 3 &&
                                    <>
                                      <div className="flex flex-col gap-3">
                                        {
                                          purchase?.buyers.slice(0, 3).map((buyer, index) => (
                                            <div key={index}>
                                              <p>{buyer.name}</p>
                                              {
                                                buyer.fbnr && <p>{buyer.fbnr}</p>
                                              }
                                            </div>
                                          ))
                                        }
                                      </div>

                                    </>
                                  }
                                  {
                                    showMultipleBuyers && (
                                      <div className="flex flex-col gap-3 mt-3">
                                        {
                                          purchase?.buyers.length > 1 && purchase?.buyers.slice(3).map((buyer, index) => (
                                            <div key={index}>
                                              <p>{buyer.name}</p>
                                              {
                                                buyer.fbnr && <p>{buyer.fbnr}</p>
                                              }
                                            </div>
                                          ))
                                        }
                                      </div>
                                    )
                                  }

                                  <div className="flex flex-col gap-3">
                                    {
                                      purchase?.buyers.length <= 3 && purchase?.buyers.map((buyer, index) => (
                                        <div key={index}>
                                          <p>{buyer.name}</p>
                                          {
                                            buyer.fbnr && <p>{buyer.fbnr}</p>
                                          }
                                        </div>
                                      ))
                                    }
                                  </div>
                                </>
                              )
                            }
                          </dd>
                        </div>

                        <div className="mt-6 px-4 pb-3 sm:grid sm:grid-cols-3 sm:gap-x-4 relative">
                          <dt className={cn("font-semibold text-gray-800 flex items-center h-6 w-max", { "cursor-pointer group": purchase?.sellers?.length > 3 })} onClick={() => purchase?.sellers?.length > 3 && setShowMultipleSellers(!showMultipleSellers)}>
                            <span className={cn({ "group-hover:underline": purchase?.sellers?.length > 3 })}>Verkäufer</span>
                            {
                              purchase?.sellers?.length > 3 && (
                                <>
                                  {!showMultipleSellers &&
                                    <button type="button">
                                      <ChevronDownIcon className="w-6 h-6 -ml-0.5 mt-0.5 text-gray-800" />
                                    </button>
                                  }
                                  {showMultipleSellers &&
                                    <button type="button">
                                      <ChevronUpIcon className="w-6 h-6 -ml-0.5 mt-0.5 text-gray-800" />
                                    </button>
                                  }
                                  <span className="text-[0.7em] rounded-full bg-primary text-white px-1 ml-1" >{purchase?.sellers?.length}</span>
                                </>
                              )
                            }
                          </dt>
                          <dd className="text-gray-900 sm:col-span-2 leading-snug relative">
                            {
                              purchase && (
                                <>
                                  {purchase?.sellers.length > 3 &&
                                    <>
                                      <div className="flex flex-col gap-3">
                                        {
                                          purchase?.sellers.slice(0, 3).map((seller, index) => (
                                            <div key={index}>
                                              <p>{[
                                                seller.first_name,
                                                seller.last_name
                                              ].filter(Boolean).join(" ")}</p>
                                              {
                                                seller.fbnr && <p>{seller.fbnr}</p>
                                              }
                                            </div>
                                          ))
                                        }
                                      </div>

                                    </>
                                  }

                                  {
                                    showMultipleSellers && (
                                      <div className="flex flex-col gap-3 mt-3">
                                        {
                                          purchase?.sellers.length > 1 && purchase?.sellers.slice(3).map((seller, index) => (
                                            <div key={index}>
                                              <p>{[
                                                seller.first_name,
                                                seller.last_name
                                              ].filter(Boolean).join(" ")}</p>
                                              {
                                                seller.fbnr && <p>{seller.fbnr}</p>
                                              }
                                            </div>
                                          ))
                                        }
                                      </div>
                                    )
                                  }

                                  <div className="flex flex-col gap-3">
                                    {
                                      purchase?.sellers.length <= 3 && purchase?.sellers.map((seller, index) => (
                                        <div key={index}>
                                          <p>{[
                                            seller.first_name,
                                            seller.last_name
                                          ].filter(Boolean).join(" ")}</p>
                                          {
                                            seller.fbnr && <p>{seller.fbnr}</p>
                                          }
                                        </div>
                                      ))
                                    }
                                  </div>
                                </>
                              )
                            }
                          </dd>
                        </div>

                      </div>

                      <div className="px-4">
                        <div className="mt-4 sm:grid sm:grid-cols-3 sm:gap-x-4">
                          <dt className="font-semibold text-gray-800">KG</dt>
                          <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-2">{purchase.kgnr} {purchase.kgname}</dd>
                        </div>

                        <div className="mt-0.5 sm:grid sm:grid-cols-3 sm:gap-x-4">
                          <dt className="font-semibold text-gray-800">EZ</dt>
                          <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-2">{purchase.ez ? purchase.ez : "keine Angabe"}</dd>
                        </div>

                        <div className="mt-0.5 sm:grid sm:grid-cols-3 sm:gap-x-4">
                          <dt className="font-semibold text-gray-800">GST-Nr</dt>
                          <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-2">{purchase.gst_number ? purchase.gst_number?.split(";").join("; ") : "keine Angabe"}</dd>
                        </div>

                        <div className="mt-0.5 sm:grid sm:grid-cols-3 sm:gap-x-4">
                          <dt className="font-semibold text-gray-800">TZ</dt>
                          <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-2">{purchase.tz}/{purchase.tz_year}</dd>
                        </div>

                        <div className="mt-0.5 sm:grid sm:grid-cols-3 sm:gap-x-4">
                          <dt className="font-semibold text-gray-800">BG</dt>
                          <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-2">{purchase.bgnr} {purchase.bgname}</dd>
                        </div>

                        <div className="mt-0.5 sm:grid sm:grid-cols-3 sm:gap-x-4">
                          <dt className="font-semibold text-gray-800">Anteile</dt>
                          <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-2">{purchase.share_part && purchase.share_total ? `${purchase.share_part}/${purchase.share_total}` : "keine Angabe"}</dd>
                        </div>

                      </div>

                      <div className="mt-4 bg-gray-50 px-4 py-3">
                        <div className="mt-0.5 sm:grid sm:grid-cols-3 sm:gap-x-4">
                          <dt className="font-semibold text-gray-800">GST-Fläche</dt>
                          <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-2">{purchase.gst_area ? formatArea(purchase.gst_area) : "keine Angabe"}</dd>
                        </div>

                        <div className="mt-0.5 sm:grid sm:grid-cols-3 sm:gap-x-4">
                          <dt className="font-semibold text-gray-800">Nutzfläche</dt>
                          <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-2">{purchase.usable_area ? formatArea(purchase.usable_area) : "keine Angabe"}</dd>
                        </div>

                        <div className="mt-0.5 sm:grid sm:grid-cols-3 sm:gap-x-4">
                          <dt className="font-semibold text-gray-800">Kaufpreis</dt>
                          <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-2">{formatEuro(parseFloat(purchase.price_netto))}</dd>
                        </div>

                        <div className="mt-0.5 sm:grid sm:grid-cols-3 sm:gap-x-4">
                          <dt className="font-semibold text-gray-800">Kaufpreis / m²</dt>
                          <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-2">{purchase.m2_price ? `${formatEuro(parseFloat(purchase.m2_price))} / m²` : "nicht kalkulierbar"}</dd>
                        </div>
                      </div>

                      <div className="mt-4 px-4 sm:grid sm:grid-cols-3 sm:gap-x-4">
                        <dt className="font-semibold text-gray-800">Flächenwidmung</dt>
                        <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-2">{purchase.widmung_short && purchase.widmung_short} - {purchase.widmung_text && purchase.widmung_text}</dd>
                      </div>

                      {purchase.coords_x && purchase.coords_y &&
                        <>
                          <div className="mt-6 text-gray-900 text-sm text-right">
                            <a
                              className="inline-flex items-center hover:underline"
                              href={`https://www.immomapping.com/immomapping/Default.aspx?mapmode=1&contractOfPurchaseId=${purchase.doc_id}`}
                              target="_blank" rel="noreferrer">
                              <span>Transaktion auf IMMOmapping</span>
                              <ArrowTopRightOnSquareIcon className="-mt-0.5 -mr-1 ml-1 w-5 h-5 text-primary" />
                            </a>
                          </div>
                          <div className="mt-1 relative h-60">
                            <PurchaseMap {...{
                              purchase,
                              slideIsOpen
                            }} />
                          </div>
                        </>
                      }
                    </dl>

                  </div>

                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
